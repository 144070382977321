import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing as VSpacingMagritte, Text as TextMagritte } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { H3Promo } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupernovaSearch from 'src/components/SupernovaSearch';
import useSearchBaseState from 'src/components/SupernovaSearch/useSearchBaseState';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useTopLevelSite } from 'src/hooks/useSites';
import { TopLevelSite } from 'src/models/topLevelSite';

import DashboardSearchTitle from 'src/components/IndexPageAnonymous/DashboardSearchTitle';

const TrlKeys = {
    header: 'index.headers.main',
    headerRuBy: 'index.headers.main.jobForEveryone',
    lookingForEmployee: 'index.search.lookingForEmployee',
};

const sendLookingForEmployeeClick = () => Analytics.sendHHEventButtonClick('looking_for_employee_index_page');

const DashboardSearch: TranslatedComponent = ({ trls }) => {
    const dashboard = useSelector(({ supernovaAnonymousDashboard }) => supernovaAnonymousDashboard);
    const lookingForEmployeeMenuItem = useSelector(({ headerMenu }) => headerMenu).find(
        (menuItem) => menuItem.name === 'lookingForEmployee'
    );
    const topLevelSite = useTopLevelSite();
    const searchBaseState = useSearchBaseState();
    const supernovaSearchState = { ...searchBaseState, isAnonymousDashboardSearch: true };
    const isZp = useIsZarplataPlatform();
    const { h1 } = useSelector((state) => state.pageMetaData);
    const isAnonymousMagritteExp = useSelector(({ isAnonymousMagritteExp }) => isAnonymousMagritteExp);

    const magritteContent = () => (
        <>
            <DashboardSearchTitle />
            <VSpacingMagritte default={32} />
            <SupernovaSearch {...supernovaSearchState} />
            {lookingForEmployeeMenuItem && (
                <>
                    <VSpacingMagritte default={32} />
                    <SPALink to={lookingForEmployeeMenuItem.url} onClick={sendLookingForEmployeeClick}>
                        <TextMagritte typography="label-2-regular" Element="span" style="contrast">
                            {trls[TrlKeys.lookingForEmployee]}
                        </TextMagritte>
                    </SPALink>
                    <VSpacing base={6} />
                </>
            )}
        </>
    );

    const blokoContent = () => (
        <>
            {isZp ? (
                <H3Promo Element="h1">{h1 || trls[TrlKeys.header]}</H3Promo>
            ) : (
                <H3Promo>
                    {[TopLevelSite.RU, TopLevelSite.BY].includes(topLevelSite)
                        ? trls[TrlKeys.headerRuBy]
                        : trls[TrlKeys.header]}
                </H3Promo>
            )}
            <VSpacing base={6} />
            <SupernovaSearch {...supernovaSearchState} />
            {lookingForEmployeeMenuItem && (
                <>
                    <VSpacing base={6} />
                    <SPALink
                        to={lookingForEmployeeMenuItem.url}
                        className="supernova-dashboard-link-switch"
                        onClick={sendLookingForEmployeeClick}
                    >
                        <Text size={TextSize.Large} Element="span">
                            {trls[TrlKeys.lookingForEmployee]}
                        </Text>
                    </SPALink>
                    <VSpacing base={6} />
                </>
            )}
        </>
    );

    return (
        <div
            className={classnames('supernova-dashboard-search', {
                'supernova-dashboard-search_with-big-promo': dashboard.bigPromoEmployerId,
            })}
        >
            {isAnonymousMagritteExp ? magritteContent() : blokoContent()}
        </div>
    );
};

export default translation(DashboardSearch);
